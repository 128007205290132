<div class="login-base">
  <div class="title">
    <div class="logo"></div>
    <span class="text">にログイン</span>
  </div>
  <form class="input-base">
    <input class="form" type="text" name="name" placeholder="ユーザ名" />
    <input class="form" type="password" name="password" placeholder="パスワード" />
    <div class="button">
      <div class="rectangle"></div>
      <span class="label">ログイン</span>
    </div>
  </form>
  <span class="navi-signup">※会員登録は<a routerLink="/form/sign-up">こちら</a></span>
</div>
