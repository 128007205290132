<div class="title">ITパスポート</div>
<div class="subtitle">ストラテジ系 - 企業と法務</div>
<div class="start-base">
  <div class="statement">モードを選択してください</div>
  <div class="button-base">
    <div class="button-section">
      <div class="button">
        <div class="text">暗記モード</div>
      </div>
      <div class="explanation">
        問題文をそのままタイピングして、<br>暗記を行うモード。
      </div>
    </div>
    <div class="button-section">
      <div class="button">
        <div class="text">解答モード</div>
      </div>
      <div class="explanation">
        問題文の一部が隠されており、<br>それを解答してタイピングするモード。
      </div>
    </div>
  </div>
</div>
