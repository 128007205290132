<div class="home-base">
  <div class="main-top">
    <div class="logo"></div>
    <span class="message">
      資格勉強とタイピング練習が同時にできる新感覚システム！<br />
      タイピングゲームを楽しみながら、資格試験に必要な文章を効率的に暗記しよう！
    </span>
    <div class="start-button n1">
      <div class="rectangle"></div>
      <a routerLink="play/select/exam" class="text">さっそく始める</a>
    </div>
    <div class="img-keyboard"></div>
    <div class="img-book"></div>
    <div class="img-pen"></div>
  </div>
  <div class="guide-detail">
    <span class="text">detail</span>
    <div class="triangle"></div>
  </div>
  <div class="gray n1"></div>
  <div class="explanation n1">
    <div class="img right"></div>
    <div class="textbox left">
      <span class="number">01</span
      ><span class="title">タイピング練習と試験勉強を同時に習得 </span
      ><span class="text"
        >タイピング練習に没頭して、ついつい長時間<br />
        取り組んでしまったことはありませんか？<br />
        そんなあなたに朗報です。<br />
        このアプリケーションでは、ただ単にタイピングを<br />
        練習するだけではなく、資格試験に関する文章を<br />
        タイピングすることで知識の定着を測ることができます。<br />
        もう、無駄な時間を過ごすことなく、<br />
        有意義な学習体験を手軽に得ることができますよ。</span
      >
    </div>
  </div>
  <div class="explanation n2">
    <div class="img left"></div>
    <div class="textbox right">
      <span class="number">02</span
      ><span class="title">知識定着を促進するの２つのモード </span
      ><span class="text"
        >当アプリケーションでは、効果的に知識を定着させるため、<br />
        2つの魅力的なモードをご用意しています。<br />
        １，資格試験の問題を解くために必要な文章をそのまま<br />
        タイピングする「暗記モード」<br />
        ２，文章の一部が穴埋め形式になっており、4つの選択肢から<br />
        正しいものを選ぶ「解答モード」<br />
        自分に合ったモードを選ぶことで、より効率的に知識を定着させる<br />
        ことができます。</span
      >
    </div>
  </div>
  <div class="gray n3"></div>
  <div class="explanation n3">
    <div class="img right"></div>
    <div class="textbox left">
      <span class="number">03</span
      ><span class="title">人気の国家資格に対応予定</span
      ><span class="text"
        >本アプリケーションは、 人気の国家資格に対応予定です。<br />
        現時点では、ITに関する基礎的な知識を証明できる<br />
        国家資格の「ITパスポート」に対応しています。<br />
        また今後は、以下の人気資格試験にも対応予定です。<br />
        ・基本情報技術者試験<br />
        ・応用情報技術者試験<br />
        ・統計検定<br />
        ・簿記検定</span
      >
    </div>
  </div>
  <div class="guide-start">
    <span class="message"
      >以下のボタンからさっそく学習を始めましょう！</span
>
    <div class="start-button n2">
    <div class="rectangle"></div>
      <a routerLink="play/select/exam" class="text">さっそく始める</a>
    </div>
  </div>
  <div class="contact-us">
    <span class="title">お問合せ / ご要望</span
    ><span class="subtitle">- Contact us -</span>
    <form>
      <input class="form n1" type="text" name="name" placeholder="件名" />
      <textarea
        class="form n2"
        type="text"
        name="name"
        placeholder="本文"
      ></textarea>
    </form>
    <div class="send-button">
      <div class="rectangle"></div>
      <span class="label">送信</span>
    </div>
  </div>
  <div class="about-developer-button">
    <div class="rectangle"></div>
    <span class="text">開発者について</span>
  </div>
  <div class="name"></div>
</div>
