<span class="title">ITパスポート</span>
<div class="score-base">
  <span class="percentage">46 %</span>
  <div class="bar-base">
    <div class="bar-box">
      <span class="subtitle question">解答モード</span>
      <div class="bar-back memory">
        <div class="bar memory"></div>
      </div>
    </div>
    <div class="bar-box">
      <span class="subtitle memory">暗記モード</span>
      <div class="bar-back question">
        <div class="bar question"></div>
      </div>
    </div>
  </div>
</div>
<!-- TODO データベースから取得してforで回す -->
<div class="select-base">
  <div class="section-base">
    <div class="subtitle">
      <div class="icon strategy"></div>
      <span class="name">ストラテジ系</span>
    </div>
    <a class="rectangle category" routerLink="/play/select/section">
      <span class="name">企業と法務</span>
    </a>
    <div class="rectangle category">
      <span class="name">経営戦略</span>
    </div>
    <div class="rectangle category">
      <span class="name">システム戦略</span>
    </div>
    <div class="rectangle summary">
      <span class="name">まとめ</span>
    </div>
  </div>
  <div class="section-base">
    <div class="subtitle">
      <div class="icon management"></div>
      <span class="name">マネジメント系</span>
    </div>
    <div class="rectangle category">
      <span class="name">開発技術</span>
    </div>
    <div class="rectangle category">
      <span class="name">プロジェクトマネジメント</span>
    </div>
    <div class="rectangle category">
      <span class="name">サービスマネジメント</span>
    </div>
    <div class="rectangle summary">
      <span class="name">まとめ</span>
    </div>
  </div>
  <div class="section-base">
    <div class="subtitle">
      <div class="icon technology"></div>
      <span class="name">テクノロジ系</span>
    </div>
    <div class="rectangle category">
      <span class="name">技術要素</span>
    </div>
    <div class="rectangle category">
      <span class="name">コンピュータシステム</span>
    </div>
    <div class="rectangle category">
      <span class="name">基礎理論</span>
    </div>
    <div class="rectangle summary">
      <span class="name">まとめ</span>
    </div>
  </div>
</div>
