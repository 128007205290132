<div class="title">ITパスポート</div>
<div class="subtitle">ストラテジ系 - 企業と法務</div>

<div class="question-base">
  <a routerLink="/play/start" class="rectangle complete-filter">
    <span class="text">企業活動 - Part１ (済)</span>
  </a>
  <div class="rectangle">
    <span class="text">企業活動 - Part２</span>
  </div>
  <div class="rectangle">
    <span class="text">企業活動 - Part３</span>
  </div>
  <div class="rectangle">
    <span class="text">法務 - Part１</span>
  </div>
  <div class="rectangle">
    <span class="text">法務 - Part２</span>
  </div>
  <a routerLink="/play/select/category" class="rectangle back">
    <span class="text">戻る</span>
  </a>
</div>
