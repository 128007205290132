<span class="title">資格を選択してください</span>
<div class="select-base">
  <div class="select-box">
    <a routerLink="/play/select/category" class="rectangle">
      <div class="name">ITパスポート</div>
      <div class="line"></div>
      <div class="icon it-pass"></div>
    </a>
    <div class="rectangle">
      <div class="name">基本情報技術者</div>
      <div class="line"></div>
      <div class="icon basic-tech"></div>
    </div>
    <div class="rectangle">
      <div class="name">応用情報技術者</div>
      <div class="line"></div>
      <div class="icon applied-tech"></div>
    </div>
  </div>
  <div class="select-box">
    <div class="rectangle">
      <div class="name">統計検定３級</div>
      <div class="line"></div>
      <div class="icon satistical-3"></div>
    </div>
    <div class="rectangle disable-filter">
      <div class="name">統計検定２級</div>
      <div class="line"></div>
      <div class="icon satistical-2"></div>
    </div>
    <div class="rectangle disable-filter">
      <div class="name">簿記検定</div>
      <div class="line"></div>
      <div class="icon bookkeeping-2"></div>
    </div>
  </div>
</div>
