<div class="login-base">
  <div class="title">
    <div class="logo"></div>
    <span class="text">に会員登録</span>
  </div>
  <form class="input-base">
    <input class="form" type="email" name="name" placeholder="登録メールアドレス" />
    <input class="form" type="text" name="name" placeholder="ユーザ名" />
    <input class="form" type="password" name="password" placeholder="パスワード" />
    <div class="button">
      <div class="rectangle"></div>
      <span class="label">会員登録</span>
    </div>
  </form>
</div>
